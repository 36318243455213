/* eslint-disable i18next/no-literal-string */
export default {
    CLIENT_URLS: {
        localhost: 'test',
        'yotoplay.local': 'dev',
        'dev-my.aws.fooropa.com': 'dev',
        'test-my.aws.fooropa.com': 'test',
        'my-beta.yotoplay.com': 'beta',
        'www-beta.yoto.io': 'beta',
        'my.yotoplay.com': 'prod',
        'www.yoto.io': 'prod'
    },

    API_DOMAINS: {
        dev: 'https://test.aws.fooropa.com',
        test: 'https://test.aws.fooropa.com',
        beta: 'https://api.yotoplay.com',
        prod: 'https://api.yotoplay.com'
    },

    MEDIA_DOMAINS: {
        dev: 'https://yoto-card-api-test-media.s3.eu-west-2.amazonaws.com',
        test: 'https://yoto-card-api-test-media.s3.eu-west-2.amazonaws.com',
        beta: 'https://card-content.yotoplay.com',
        prod: 'https://card-content.yotoplay.com'
    },
    ICONS_DOMAINS: {
        dev: 'https://icons-test.aws.fooropa.com',
        test: 'https://icons-test.aws.fooropa.com',
        beta: 'https://icons-test.aws.fooropa.com',
        prod: 'https://icons.api.yotoplay.com'
    },
    DEVICE_PREFIXES: {
        dev: { v1: 'dev_', v2: 'd2' },
        test: { v1: 'test_', v2: 't2' },
        beta: { v1: '', v2: 'y2' },
        prod: { v1: '', v2: 'y2' }
    }
};
