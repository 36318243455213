import * as api from './yoto-api';
import { isPromise } from './yoto-api';
import STAGES from './stages';

const MEDIA_DOMAIN =
    STAGES.MEDIA_DOMAINS[STAGES.CLIENT_URLS[window.location.hostname]];

const YOTO_MEDIA_URL_PREFIX = 'yoto:#';
const HTTPS_PREFIX = 'https://';
const HTTP_PREFIX = 'http://';

function isUrl(mediaId) {
    return mediaId.startsWith(HTTP_PREFIX) || mediaId.startsWith(HTTPS_PREFIX);
}

export async function getTrackDisplayIconURL(mediaId) {
    if (!mediaId) {
        return null;
    }
    if (!isUrl(mediaId)) {
        return await api.getDisplayIconV2Url(mediaId);
    }
    return mediaId;
}

export function getIconUrl(mediaId, url) {
    if (!mediaId) return null;
    if (url) {
        return url;
    }
    if (isPromise(mediaId)) {
        return mediaId.then((data) => {
            return data;
        });
    }

    if (isUrl(mediaId)) return mediaId;
    if (mediaId.startsWith(YOTO_MEDIA_URL_PREFIX))
        mediaId = mediaId.split(YOTO_MEDIA_URL_PREFIX)[1];
    return `${MEDIA_DOMAIN}/icons/${mediaId}`;
}
