import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
  Tabs,
  Tab,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorIcon from "@material-ui/icons/Error";

import { Trans } from "react-i18next";
import { uploadIcon } from "../../utils/yoto-api";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import * as iconService from "../../utils/iconsService";
const api = require("../../utils/yoto-api");

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(() => ({
  paper: {
    margin: 0,
    padding: 0,
    maxHeight: "88vh",
    minHeight: "88vh",
    //        backgroundColor: '#000'
  },
  appBar: {
    position: "relative",
  },
  title: {
    //        flex: 1,
    backgroundColor: "#F95E3F",
    color: "#fff",
    margin: 0,
  },
}));

const IconSelector = ({ chapterKey, open, onChange, onClose }) => {
  const [yotoIcons, setYotoIcons] = useState(false);
  const [userIcons, setUserIcons] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [deleteMode, setDeleteMode] = useState(false);
  const [iconsToDelete, setIconsToDelete] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [applyToAll, setApplyToAll] = useState(false);
  const [error, setError] = useState(null);

  const classes = useStyles();

  const loadUserIcons = useCallback(async () => {
    const userIcons = (await api.getUserDisplayIcons()).sort((i1, i2) =>
      i2.createdAt.localeCompare(i1.createdAt),
    );
    setUserIcons(userIcons);
  }, [setUserIcons]);

  useEffect(() => {
    const asyncEffect = async () => {
      const yotoIcons = await api.getYotoDisplayIcons();
      if (yotoIcons && yotoIcons.length) {
        setYotoIcons(
          yotoIcons
            .filter((i) => i.public)
            .sort((i1, i2) =>
              (i2.publicTags.join(" ") || "").localeCompare(
                i2.publicTags.join(" ") || "",
              ),
            ),
        );
        await loadUserIcons();
      }
    };
    asyncEffect();
  }, [setYotoIcons, loadUserIcons]);

  const handleClose = () => {
    onClose();
  };

  const exitDeleteMode = useCallback(() => {
    setIconsToDelete([]);
    setDeleteMode(false);
  }, []);

  const deleteSelectedIcons = useCallback(async () => {
    for (const displayIconId of iconsToDelete)
      await api.deleteDisplayIcon(displayIconId);
    await loadUserIcons();
    exitDeleteMode();
  }, [iconsToDelete, exitDeleteMode, loadUserIcons]);

  const deleteButtonAction = useCallback(() => {
    if (deleteMode) {
      deleteSelectedIcons();
    } else {
      setDeleteMode(true);
    }
  }, [deleteMode, setDeleteMode, deleteSelectedIcons]);

  const selectTab = useCallback(
    (tab) => {
      setSelectedTab(tab);
      if (deleteMode) setDeleteMode(false);
    },
    [setSelectedTab, deleteMode, setDeleteMode],
  );

  const selectIconAction = useCallback(
    (icon, chapterKey) => {
      const { displayIconId, mediaId, url } = icon;
      if (!deleteMode) {
        onChange(applyToAll ? null : chapterKey, { mediaId, url });
      } else {
        if (iconsToDelete.includes(displayIconId)) {
          setIconsToDelete((prev) => {
            const now = prev.filter((i) => i !== displayIconId);
            return now;
          });
        } else {
          setIconsToDelete((prev) => [...prev, displayIconId]);
        }
      }
    },
    [deleteMode, iconsToDelete, setIconsToDelete, applyToAll, onChange],
  );

  const uploadIconFile = useCallback(async (file) => {
    if (/\.(png|gif|jpg|jpeg|svg|tif|tiff)$/i.test(file.name) === false) {
      return;
    }
    await uploadIcon(file);
  }, []);

  const showError = useCallback(
    async (err) => {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      setError(err);
      await delay(3000);
      setError(null);
    },
    [setError],
  );

  const handleFileSelect = useCallback(
    async (event) => {
      setUploading(true);
      const files = event.target.files; // FileList object

      try {
        for (const file of files) {
          await uploadIconFile(file);
        }
        await loadUserIcons();
        event.target.value = null;
      } catch (error) {
        await showError(error);
      }

      setUploading(false);
    },
    [uploadIconFile, loadUserIcons, setUploading, showError],
  );

  const onIconChange = (chapterKey, { mediaId, url }) => {
    onChange(applyToAll ? null : chapterKey, { mediaId, url });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={() => handleClose()}
      className={useStyles.root}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle color="primary" disableTypography className={classes.title}>
        <Typography variant="h6">
          <Trans i18nKey="myo_select_icon_title">Select icon</Trans>
        </Typography>
        {onClose ? (
          <IconButton
            style={{ position: "absolute", top: "8px", right: "8px" }}
            aria-label={<Trans i18nKey="common_close">Close</Trans>}
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        value={selectedTab}
        onChange={(e, tab) => {
          selectTab(tab);
        }}
      >
        <Tab
          color="primary"
          label={<Trans i18nKey="myo_icons_yoto_icons_tab">Yoto Icons</Trans>}
        />
        <Tab
          label={
            <>
              <Trans i18nKey="myo_icons_my_icons_tab">My Icons</Trans>
            </>
          }
        />
      </Tabs>
      <DialogContent>
        <TabPanel value={selectedTab} index={0}>
          {
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {!yotoIcons
                ? ""
                : yotoIcons.map((icon) => (
                    <div
                      style={{
                        backgroundColor: "#322",
                        borderRadius: "5px",
                        margin: "2px",
                        width: "48px",
                        height: "48px",
                      }}
                      key={icon.mediaId}
                      onClick={() => onIconChange(chapterKey, icon)}
                    >
                      <img
                        className="trackIcon"
                        style={{ width: "48px", minWidth: "48px" }}
                        alt=""
                        src={`${iconService.getIconUrl(
                          icon.mediaId,
                          icon.url,
                        )}`}
                        width="48"
                        height="48"
                        loading="lazy"
                      />
                    </div>
                  ))}
            </div>
          }
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            multiple
            onChange={async (event) => {
              await handleFileSelect(event);
            }}
            style={{ display: "none" }}
          />

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {/*<div style={{ backgroundColor: '#322', borderRadius: '5px',  margin: '2px' }} >
                                <AddIcon color='primary' fontSize='large'/>
                            </div>*/}
            {uploading ? (
              <div
                style={{
                  backgroundColor: "#322",
                  borderRadius: "5px",
                  padding: "8px",
                  margin: "2px",
                  width: "48px",
                  height: "48px",
                }}
              >
                {!error ? (
                  <CircularProgress size="32px" color="primary" />
                ) : (
                  <ErrorIcon fontSize="large" color="primary" />
                )}
              </div>
            ) : (
              ""
            )}
            {!userIcons
              ? ""
              : userIcons.map((icon) => {
                  return (
                    <div
                      key={`container-${icon.displayIconId}`}
                      style={{ position: "relative" }}
                      onClick={() => selectIconAction(icon, chapterKey)}
                    >
                      <div
                        style={{
                          backgroundColor: "#322",
                          borderRadius: "5px",
                          margin: "2px",
                          width: "48px",
                          height: "48px",
                          opacity:
                            deleteMode &&
                            !iconsToDelete.includes(icon.displayIconId)
                              ? 0.5
                              : 1,
                        }}
                        key={icon.mediaId}
                      >
                        <img
                          className="trackIcon"
                          style={{
                            width: "48px",
                            minWidth: "48px",
                            opacity:
                              deleteMode &&
                              iconsToDelete.includes(icon.displayIconId)
                                ? "0.5"
                                : "1",
                          }}
                          alt=""
                          src={`${iconService.getIconUrl(
                            icon.mediaId,
                            icon.url,
                          )}`}
                        />
                      </div>
                      <span
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          padding: "9px",
                          display:
                            deleteMode &&
                            iconsToDelete.includes(icon.displayIconId)
                              ? "block"
                              : "none",
                        }}
                      >
                        <CheckCircleIcon color="primary" fontSize="large" />
                      </span>
                    </div>
                  );
                })}
          </div>
          <Typography style={{ padding: "10px 0", marginTop: "5px" }}>
            <Trans i18nKey="myo_icons_upload_instructions">
              Upload your own icons in PNG, GIF, JPG, TIF or SVG format. For
              best results prepare your image as 16x16 PNG with transparent
              background and avoiding black (which will not show on display).
            </Trans>
          </Typography>
        </TabPanel>
      </DialogContent>
      <div
        style={{
          bottom: 0,
          left: 0,
          padding: "10px",
          backgroundColor: "#fff",
          width: "100%",
          position: "sticky",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6} style={{ minWidth: "260px" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                marginLeft: "10px",
              }}
            >
              {!uploading && !deleteMode ? (
                <FormControlLabel
                  disabled={uploading || deleteMode}
                  control={
                    <Checkbox
                      checked={applyToAll}
                      onChange={(e) => {
                        setApplyToAll(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Trans i18nKey="myo_icons_apply_to_all_tracks_button">
                      Apply to all tracks
                    </Trans>
                  }
                />
              ) : error ? (
                <div style={{ color: "red" }}>
                  <Trans i18nKey="myo_icons_upload_failed_message">
                    Unrecognized format or upload failed.
                  </Trans>
                </div>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item xs={6} style={{ minWidth: "260px" }}>
            <div style={{ display: "flex", width: "100%", height: "100%" }}>
              {selectedTab !== 1 || deleteMode ? (
                ""
              ) : (
                <>
                  <label htmlFor="contained-button-file">
                    <Button
                      style={{ margin: "0px 8px" }}
                      variant="contained"
                      color="primary"
                      component="span"
                      disabled={uploading}
                    >
                      <AddIcon size="small" fontSize="small" />
                      <Trans i18nKey="myo_icons_upload_button">
                        Upload icon
                      </Trans>
                    </Button>
                  </label>
                  {/*<Button
                                variant={deleteMode ? 'contained' : 'outlined'}
                                color="primary"
                                disabled={uploading}
                                onClick={() => {
                                    deleteButtonAction();
                                }}
                            ><DeleteIcon/> Delete icon(s)
                            </Button>*/}
                  <IconButton
                    size="small"
                    disabled={uploading || !userIcons || userIcons.length === 0}
                    onClick={() => {
                      deleteButtonAction();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
              {selectedTab !== 1 || !deleteMode ? (
                ""
              ) : (
                <>
                  <Button
                    style={{ margin: "0px 8px" }}
                    variant={deleteMode ? "contained" : "outlined"}
                    color="default"
                    onClick={() => {
                      exitDeleteMode();
                    }}
                  >
                    <Trans i18nKey="common_cancel_button">Cancel</Trans>
                  </Button>
                  <Button
                    style={{ margin: "0px 8px" }}
                    variant={deleteMode ? "contained" : "outlined"}
                    color="primary"
                    disabled={iconsToDelete.length === 0}
                    onClick={() => {
                      deleteButtonAction();
                    }}
                  >
                    <Trans i18nKey="myo_icons_delete_selected_button">
                      Delete selected
                    </Trans>
                  </Button>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

IconSelector.propTypes = {
  chapterKey: PropTypes.string,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  open: PropTypes.bool,
};

export default IconSelector;
