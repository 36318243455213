import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { sortableElement, sortableHandle } from "react-sortable-hoc";
import {
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CheckIcon from "@material-ui/icons/Check";
import { getTrackDisplayIconURL } from "../../utils/iconsService";

const propTypes = {
  chapterIndex: PropTypes.number,
  chapter: PropTypes.object,
  readOnly: PropTypes.bool,
  deleteChapter: PropTypes.func,
  updateChapterTitle: PropTypes.func,
  handleIconDialogOpen: PropTypes.func,
  updateChapterOnEndEvent: PropTypes.func,
  params: PropTypes.object,
  i18n: PropTypes.object,
};

const STATUS_FAILED = "failed";
const STATUS_TRANSCODING = "transcode";

const IMG_REORDER = "/img/reorder.svg";

const TRACK_LIMIT = 100;

const ON_END_STOP = "stop";
const ON_END_NONE = "none";
const ON_END_REPEAT = "repeat";

const SELECTED = <CheckIcon style={{ paddingLeft: "8px" }} />;

const trackDisplayIcon = async (chapter, track, userId) => {
  const mediaId =
    track.display && track.display.icon16x16
      ? track.display.icon16x16
      : chapter.display.icon16x16;
  return getTrackDisplayIconURL(mediaId);
};

const formatSize = (size) => {
  if (!size) {
    return "";
  }
  const kb = Math.floor(size / 1024);
  const mb = Math.floor((kb / 1024) * 10) / 10;
  if (mb === 0) return ` ${kb}KB`;
  return ` ${mb}MB`;
};

const DragHandle = sortableHandle(({ value }) => <>{value}</>);

const SortableItem = sortableElement(
  ({
    chapter,
    track,
    chapterIndex,
    i18n,
    readOnly,
    onDeleteChapter,
    onUpdateChapterTitle,
    handleIconDialogOpen,
    menuAnchorEl,
    handleChapterMenuOpen,
    handleChapterMenuCancel,
    onUpdateChapterOnEndEvent,
    trackEndEvent,
  }) => {
    const [iconUrl, setIconUrl] = useState("");
    useEffect(() => {
      const fetchIconUrlAsync = async () => {
        const url = await trackDisplayIcon(chapter, track);
        setIconUrl(url);
      };

      fetchIconUrlAsync();
    }, [chapter, track, onUpdateChapterOnEndEvent]);

    return (
      <div
        key={`track-container-${chapterIndex}`}
        style={{
          borderBottomStyle: "dotted",
          borderBottomColor: "#ccc",
          borderTopStyle: "dotted",
          borderTopColor: "#ccc",
          marginTop: "-2px",
        }}
      >
        <table>
          <tbody>
            <tr key={`track_tr_${chapterIndex}`}>
              {readOnly ? (
                ""
              ) : (
                <DragHandle
                  value={
                    <td style={{ cursor: "grab" }}>
                      {" "}
                      <img
                        alt={i18n.t(
                          "myo_track_drag_to_reorder",
                          "Drag to reorder",
                        )}
                        src={IMG_REORDER}
                      />
                    </td>
                  }
                />
              )}
              <td>
                <img
                  alt={i18n.t("myo_track_choose_icon", "Choose icon")}
                  className="trackIcon"
                  src={iconUrl}
                  style={{
                    margin: "10px",
                    cursor: readOnly ? "default" : "pointer",
                  }}
                  onClick={() => !readOnly && handleIconDialogOpen(chapter.key)}
                />
              </td>
              <td style={{ padding: "0 8px 3px 0", textAlign: "right" }}>
                {track.overlayLabel ? `${track.overlayLabel}.` : ""}
                {track._control && track._control.statusMessage ? (
                  <div
                    style={{
                      fontSize: "0.8em",
                      opacity: "0.75",
                      color:
                        track._control &&
                        track._control.statusCode === STATUS_FAILED
                          ? "red"
                          : "black",
                    }}
                  >
                    &nbsp;
                  </div>
                ) : (
                  ""
                )}
              </td>
              <td style={{ wordBreak: "break-all", width: "100%" }}>
                <InputBase
                  fullWidth
                  id={`chapter-${chapter.key}-title`}
                  variant="standard"
                  value={`${track.title}`}
                  multiline
                  disabled={
                    readOnly || (track._control && track._control.statusMessage)
                  }
                  onChange={(event) =>
                    onUpdateChapterTitle(
                      chapter.key,
                      event.target.value.substring(0, 255),
                    )
                  }
                />

                {track._control && track._control.statusMessage ? (
                  <div
                    style={{
                      fontSize: "0.8em",
                      opacity: "0.75",
                      color:
                        track._control &&
                        track._control.statusCode === STATUS_FAILED
                          ? "red"
                          : "black",
                    }}
                  >
                    {track._control.statusMessage}
                  </div>
                ) : (
                  ""
                )}
                {chapterIndex >= TRACK_LIMIT ? (
                  <div
                    style={{ fontSize: "0.8em", opacity: "0.75", color: "red" }}
                  >
                    {i18n.t(
                      "myo_track_limit_reached",
                      `Up to ${TRACK_LIMIT} tracks allowed and 100MB per track, card limited to 500MB in total`,
                    )}
                  </div>
                ) : (
                  ""
                )}
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                {formatSize(track.fileSize)}
              </td>
              <td style={{ whiteSpace: "nowrap" }}>
                {!track._control ||
                !track._control.statusCode ||
                [STATUS_FAILED, STATUS_TRANSCODING].includes(
                  track._control.statusCode,
                ) ? (
                  <>
                    <div>
                      {trackEndEvent === ON_END_REPEAT ? (
                        <img
                          className="trackEndEventIcon"
                          alt={i18n.t(
                            "myo_track_on_end_repeat",
                            "Repeat track",
                          )}
                          src="/img/track-repeat.svg"
                        />
                      ) : trackEndEvent === ON_END_STOP ? (
                        <img
                          className="trackEndEventIcon"
                          alt={i18n.t(
                            "myo_track_on_end_pause",
                            "Pause, wait for button press",
                          )}
                          src="/img/track-pause.svg"
                        />
                      ) : (
                        ""
                      )}
                      {readOnly ? (
                        ""
                      ) : (
                        <IconButton
                          id={`menu-button-${chapter.key}`}
                          aria-controls={`chapter-menu-${chapter.key}`}
                          aria-haspopup={true}
                          onClick={handleChapterMenuOpen}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                    </div>
                    <Menu
                      id={`chapter-menu-${chapter.key}`}
                      keepMounted
                      disableScrollLock
                      anchorEl={menuAnchorEl}
                      open={Boolean(menuAnchorEl)}
                      onClose={handleChapterMenuCancel}
                    >
                      <MenuList dense style={{ paddingRight: "10px" }}>
                        <MenuItem
                          divider
                          onClick={() => onUpdateChapterOnEndEvent(ON_END_NONE)}
                        >
                          {i18n.t(
                            "myo_track_on_end_continue",
                            "Continue to next track",
                          )}
                          {trackEndEvent === ON_END_NONE ? SELECTED : ""}
                        </MenuItem>
                        <MenuItem
                          divider
                          onClick={() => onUpdateChapterOnEndEvent(ON_END_STOP)}
                        >
                          {i18n.t(
                            "myo_track_on_end_pause",
                            "Pause, wait for button press",
                          )}
                          {trackEndEvent === ON_END_STOP ? SELECTED : ""}
                        </MenuItem>
                        <MenuItem
                          divider
                          onClick={() =>
                            onUpdateChapterOnEndEvent(ON_END_REPEAT)
                          }
                        >
                          {i18n.t(
                            "myo_track_on_end_repeat",
                            "Repeat this track",
                          )}
                          {trackEndEvent === ON_END_REPEAT ? SELECTED : ""}
                        </MenuItem>
                        <MenuItem
                          style={{ color: "red" }}
                          onClick={() => onDeleteChapter(chapter.key)}
                        >
                          {i18n.t("myo_track_delete", "Delete track")}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </>
                ) : (
                  ""
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  },
);

class SortableChapterTrack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuAnchorEl: null,
      chapterIndex: props.chapterIndex,
      chapter: props.chapter,
      handleIconDialogOpen: props.handleIconDialogOpen,
      updateChapterOnEndEvent: props.updateChapterOnEndEvent,
    };
    this.i18n = this.props.i18n;
  }

  handleChapterMenuOpen(event) {
    this.setState({ menuAnchorEl: event.currentTarget });
  }

  handleChapterMenuCancel() {
    this.setState({ menuAnchorEl: null });
  }

  handleChapterMenuSelect(onEndEvent) {
    this.setState({ menuAnchorEl: null });
    this.props.updateChapterOnEndEvent(this.state.chapter.key, onEndEvent);
  }

  render() {
    const { chapter, chapterIndex, card } = this.state;
    const track = chapter && chapter.tracks && chapter.tracks[0];
    const i18n = this.props.i18n;
    //if (track.events) console.log(track.events);
    let trackEndEvent = ON_END_NONE;
    if (track.events && track.events.onEnd) {
      trackEndEvent = track.events.onEnd.cmd;
    }
    const userId = card && card.userId;
    return (
      <SortableItem
        userId={userId}
        key={`track${chapter.key}_${chapterIndex}`}
        i18n={i18n}
        chapterIndex={chapterIndex}
        index={chapterIndex}
        chapter={chapter}
        track={track}
        readOnly={this.props.readOnly}
        handleIconDialogOpen={(chapterKey) =>
          this.props.handleIconDialogOpen(chapterKey)
        }
        onUpdateChapterTitle={(chapterKey, title) =>
          this.props.updateChapterTitle(chapterKey, title)
        }
        onDeleteChapter={(chapterKey) => this.props.deleteChapter(chapterKey)}
        menuAnchorEl={this.state.menuAnchorEl}
        handleChapterMenuOpen={(event) => this.handleChapterMenuOpen(event)}
        handleChapterMenuCancel={() => this.handleChapterMenuCancel()}
        onUpdateChapterOnEndEvent={(onEndEvent) =>
          this.handleChapterMenuSelect(onEndEvent)
        }
        trackEndEvent={trackEndEvent}
      >
        {i18n.t("myo_track_drag_to_reorder", "Drag to reorder")}
      </SortableItem>
    );
  }
}

SortableChapterTrack.propTypes = propTypes;

export default withTranslation()(SortableChapterTrack);
